import { useEffect, useRef } from 'react';

import { motion } from 'framer-motion';
import Lottie, { LottieRefCurrentProps } from 'lottie-react';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import OverlayInstructions from './OverlayInstructions';

import animation from '../../assets/animation/man-with-phone.json';

import './OverlayPrompt.scss';

const OverlayPrompt: React.FC = () => {
  const lottieRef = useRef<LottieRefCurrentProps>(null);

  useEffect(() => {
    lottieRef.current?.setSpeed(1.5);
  }, []);

  return (
    <Box
      className="overlay-prompt"
      key="overlay-prompt"
      component={motion.div}
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ ease: 'easeIn', duration: 0.35 }}
    >
      <Box className="overlay-content">
        <Typography
          className="app-text"
          variant="subtitle1"
          sx={{
            fontWeight: 600,
            lineHeight: '22px',
            letterSpacing: '-0.408px',
          }}
        >
          FIND YOUR FLOOR
        </Typography>

        <OverlayInstructions />

        <Typography
          className="app-text"
          variant="body2"
          sx={{ textAlign: 'center', lineHeight: 'normal' }}
        >
          1. Stand about 2m (6ft) from the floor and hold your phone at eye
          level.
        </Typography>

        <Lottie
          lottieRef={lottieRef}
          className="move-device-animation"
          animationData={animation}
        />

        <Typography
          className="app-text"
          variant="body2"
          sx={{ textAlign: 'center', lineHeight: 'normal' }}
        >
          2. Hold your phone at a 45° angle toward the ground and move it away
          and toward yourself.
        </Typography>
      </Box>

      <Box className="overlay-scanning" key="overlay-scanning">
        <Typography className="app-text" variant="subtitle1">
          Scanning...
        </Typography>
      </Box>
    </Box>
  );
};

export default OverlayPrompt;
