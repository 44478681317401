import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import DisclaimerTitle from './DisclaimerTitle';
import DisclaimerSubTitle from './DisclaimerSubTitle';
import DisclaimerLink from './DisclaimerLink';

const PrivacyPolicyContent: React.FC = () => (
  <>
    <DisclaimerTitle>Privacy Policy</DisclaimerTitle>
    <Typography className="app-text" variant="caption" component="p">
      This Privacy Policy explains how DUFRESNE (<b>&quot;we&quot;</b>,
      <b>&quot;us&quot;</b>, or <b>&quot;our&quot;</b>) may collect, use, and
      disclose your personal information in connection with specific
      applications and services (collectively, the &quot;Services&quot;).
    </Typography>
    <DisclaimerSubTitle>Changes to our Privacy Policy</DisclaimerSubTitle>
    <Typography className="app-text" variant="caption" component="p">
      We reserve the right to amend this Privacy Policy at our discretion and at
      any time. When we make changes to this Privacy Policy, we will provide
      adequate notice and update the “Last Updated” date above. Your continued
      use of the <b>Services</b> following the posting of changes constitutes
      your acceptance of such changes.
    </Typography>
    <DisclaimerSubTitle>Personal information we collect</DisclaimerSubTitle>
    <Typography className="app-text" variant="caption" component="p">
      The following are categories (with non-exhaustive examples) of personal
      information we may collect about you:
    </Typography>
    <Typography className="app-text" variant="caption" component="p">
      <b>Personal Information You Provide.</b> We collect personal information
      you provide to us through usage of our <b>Services</b>, such as when you
      log on to app, communicate with or submit information or content to us,
      respond to a survey or register for White Glove delivery. This information
      includes, but is not limited to email address, name, video clips, photos
      and survey responses.
    </Typography>
    <Typography className="app-text" variant="caption" component="p">
      <b>Information From Other Sources.</b> Advertisers, measurement and other
      partners may share information with us about you and the actions you have
      taken outside of our <b>Services</b>, such as your activities on other
      websites and apps or in stores, including the products or services you
      purchased, online or in person. These partners may also share information
      with us, such as mobile identifiers for advertising, hashed email
      addresses and phone numbers, and cookie identifiers, which we use to help
      match you and your actions outside of the Services. We may obtain
      information about you from certain affiliated entities within our
      corporate group.
    </Typography>
    <DisclaimerSubTitle>Automatically Collected Information</DisclaimerSubTitle>
    <Typography className="app-text" variant="caption" component="p">
      <b>Automatic Data Collection, Cookies, and Related Technologies.</b>
      The <b>Services</b> collect and store information that is generated
      automatically as you use it, including your preferences and anonymous
      usage statistics. We, our service providers, and our business partners may
      automatically log personal information about you, your computer or device,
      and your activity over time on our
      <b>Services</b> and other sites and services, such as identifiers, device
      data, online activity data, and geolocation data, including by using
      cookies and similar technology. Like many online services, the Services
      may use the following technologies to facilitate our automatic data
      collection:
    </Typography>
    <Box component="ul">
      <Typography className="app-text" variant="caption" component="li">
        <b>Cookies</b>, which are small text files placed in visitor&apos;s
        computer browsers to store their preferences, make navigation between
        pages more efficient, help us understand user activity and patterns, and
        facilitate online advertising.
      </Typography>
      <Typography className="app-text" variant="caption" component="li">
        <b>Web beacons</b>, also known as pixel tags or clear GIFs, which are
        typically used to demonstrate that a webpage or email was accessed or
        opened, or that certain content was viewed or clicked, typically to
        compile statistics about usage of websites and the success of marketing
        campaigns.
      </Typography>
      <Typography className="app-text" variant="caption" component="li">
        <b>Software development kits</b>, or SDKs, which are third-party
        computer code used in connection with our <b>Services</b> for a variety
        of purposes, including to provide us with analytics regarding the use of
        our <b>Services</b>, to integrate with social media, add features or
        functionality to our Services, or to facilitate online advertising. SDKs
        may enable third parties to collect information directly via our{' '}
        <b>Services</b>.
      </Typography>
    </Box>
    <Typography className="app-text" variant="caption" component="p">
      <b>Image and Video Information</b>. We may collect information about the
      videos and images that are generated either through the delivery process
      or through direct usage of any Services, such as identifying the objects
      and background that appear. We will NOT collect any information related to
      face or body features or any audio. We may collect this information for
      demographic classification, for content and ad recommendations, and to
      enrich your customer profile for use by our retail marketing team.
    </Typography>
    <DisclaimerSubTitle>How we collect your information</DisclaimerSubTitle>
    <Typography className="app-text" variant="caption" component="p">
      We collect information about you in various ways, including in connection
      with the sources described below.
    </Typography>
    <Typography className="app-text" variant="caption" component="p">
      <b>Environmental Background Data</b>. We collect information from the
      photos and videos taken in conjunction with White Glove delivery services
      and usage of our visualization App using object and background recognition
      and computer vision technology.
    </Typography>
    <Typography className="app-text" variant="caption" component="p">
      <b>Business Partners and Other Third Parties.</b> We may also collect
      information about you from our business partners, retail customers, social
      media networks and data providers, and we may add that information to
      information you provide to us and other personal information we collect.
    </Typography>
    <Typography className="app-text" variant="caption" component="p">
      <b>Social Media Platforms.</b> You may register using a social network,
      such as Facebook or Pinterest. We collect information from the social
      network when you use your social media credentials to log into the
      Services. For example, when you log in with your Facebook credentials, we
      may collect the information you have made publicly available in Facebook,
      such as your name and profile picture. We may also obtain any other
      information you have authorized Facebook to share with third party
      applications. For example, if you authorize us to access your Pinterest or
      Houzz accounts, we may access and collect the photos, images, or other
      content that you have pinned there. We may also collect social media
      information when you interact with us via our social media accounts.
    </Typography>
    <Typography className="app-text" variant="caption" component="p">
      <b>Location Information from Your Mobile Device</b>. We may collect and
      store your location information if you enable your mobile device to send
      it to us.
    </Typography>
    <Typography className="app-text" variant="caption" component="p">
      <b>Publicly Available Information</b>. We may collect information about
      you from publicly available sources and where permitted by applicable law,
      or with your permission, make it available as part of our Services.
    </Typography>
    <DisclaimerSubTitle>
      How we use your personal information
    </DisclaimerSubTitle>
    <Typography className="app-text" variant="caption" component="p">
      <b>Service Delivery</b>. We use information to deliver services including,
      but not limited to:
    </Typography>
    <Box component="ul">
      <Typography className="app-text" variant="caption" component="li">
        <b>Product Recommendations</b>. Your information will be used to provide
        more targeted product recommendations either a) on demand when requested
        through use of our room scanning App or b) in the ad content you see
        through various marketing channels.
      </Typography>
    </Box>
    <Typography className="app-text" variant="caption" component="p">
      <b>Marketing</b>. We and our partners may use your personal information
      for direct marketing from our retail partners and interest-based
      advertising purposes.
    </Typography>
    <Box component="ul">
      <Typography className="app-text" variant="caption" component="li">
        <b>Direct Marketing</b>. Our retail partners may use information
        (including your email) to provide online advertising on their website
        and to facilitate transmittal of information that may be useful,
        relevant, valuable, or otherwise of interest to you. This may be
        information about their products and services or third-party products
        and services. You will always have the option to opt-out from direct
        electronic marketing.
      </Typography>
      <Typography className="app-text" variant="caption" component="li">
        <b>Interest-Based Advertising</b>. We also contract with our business
        partners, third-party advertising companies, and social media companies
        to display ads on various sites and services. These companies may use
        cookies and similar technologies to collect information about you
        (including the device data and online activity data described above)
        over time across our
        <b>Services</b> and other sites and services or your interaction with
        our emails, and use that information to serve ads that they think will
        interest you. You can learn more about your choices for limiting
        interest-based advertising, in the “Advertising Choices” section below.
      </Typography>
    </Box>
    <Typography className="app-text" variant="caption" component="p">
      <b>Research and Development</b>. We may use your personal information for
      testing, research, analysis, and product development, including to develop
      and improve our <b>Services</b>, products, and services and to create new
      products and services. We may anonymize and aggregate data collected
      through the <b>Services</b> by removing information that makes the data
      personally identifiable to you, which we may use or share for any purpose,
      including for marketing purposes and to analyze and improve the{' '}
      <b>Services</b>.
    </Typography>
    <Typography className="app-text" variant="caption" component="p">
      <b>Compliance and Protection</b>. We may use your personal information to:
    </Typography>
    <Box component="ul">
      <Typography className="app-text" variant="caption" component="li">
        comply with applicable laws, lawful requests, and legal process, such as
        to respond to subpoenas or requests from government authorities;
      </Typography>
      <Typography className="app-text" variant="caption" component="li">
        protect our, your or other&apos;s rights, privacy, safety, or property
        (including by making and defending legal claims);
      </Typography>
      <Typography className="app-text" variant="caption" component="li">
        audit our internal processes for compliance with legal and contractual
        requirements and internal policies;
      </Typography>
      <Typography className="app-text" variant="caption" component="li">
        enforce the terms and conditions that govern the Services; and
      </Typography>
      <Typography className="app-text" variant="caption" component="li">
        prevent, identify, investigate, and deter fraudulent, harmful,
        unauthorized, unethical, or illegal activity, including cyberattacks and
        identity theft.
      </Typography>
    </Box>
    <Typography className="app-text" variant="caption" component="p">
      <b>Other Uses</b>. In addition to the uses otherwise described in this
      Privacy Policy, we may also use your personal information as described to
      you at the time when we collect it.
    </Typography>
    <DisclaimerSubTitle>
      How we may disclose your personal information
    </DisclaimerSubTitle>
    <Typography className="app-text" variant="caption" component="p">
      We may share your personal information with the categories listed below
      and as otherwise described in this Privacy Policy or at the time of
      collection. We do not sell your personal information or share your
      personal information with third parties where restricted by applicable
      law.
    </Typography>
    <Typography className="app-text" variant="caption" component="p">
      <b>Retail Partners and Related Companies</b>. We may share data collected
      by using the Services with our retail partners to use in the delivery of
      digital and offline marketing or otherwise facilitate transmittal of
      information that may be useful, relevant, valuable, or otherwise of
      interest to you. Our retail partners may collect content from the Services
      and information about your activity on the Services to help them advertise
      their products and services, and/or use hashed customer lists that we
      share with them to deliver ads similar users on their platforms. We may
      share your personal information with our current or future affiliates
      and/or subsidiaries.
    </Typography>
    <Typography className="app-text" variant="caption" component="p">
      <b>Social Networking and Other Websites</b>. The Services may allow you to
      share information with social networking websites, such as Facebook. Their
      use of the information will be governed by their privacy policies, and you
      may be able to modify your privacy settings on their websites.
    </Typography>
    <Typography className="app-text" variant="caption" component="p">
      <b>Social Networking and Other Websites</b>. The Services may allow you to
      share information with social networking websites, such as Facebook. Their
      use of the information will be governed by their privacy policies, and you
      may be able to modify your privacy settings on their websites.
    </Typography>
    <Typography className="app-text" variant="caption" component="p">
      <b>As Required by Law and Similar Disclosures</b>. . We may access,
      preserve, and disclose your information if we believe doing so is required
      or appropriate to: comply with law enforcement requests and legal process,
      such as a court order or subpoena; respond to your requests; protect
      your&apos;s, our&apos;s or other&apos;s rights, property, or safety; or
      for the fraud prevention and safety purposes described above.
    </Typography>
    <Typography className="app-text" variant="caption" component="p">
      <b>Merger, Sale, or Other Asset Transfers</b>. If we are involved in a
      merger, acquisition, financing due diligence, reorganization, bankruptcy,
      receivership, sale of company assets, or transition of service to another
      provider, including any related negotiations, your information may be
      sold, disclosed, or transferred as part of such a transaction (or
      potential transaction), as allowed by law. If another company acquires our
      company or our assets, that company will possess the information collected
      by it and us and will assume the rights and obligations regarding your
      information collected by us as described in this policy.
    </Typography>
    <DisclaimerSubTitle>Security of your information</DisclaimerSubTitle>
    <Typography className="app-text" variant="caption" component="p">
      We take steps to ensure that your information is treated securely and in
      accordance with this Privacy Policy. Unfortunately, the Internet cannot be
      guaranteed to be 100% secure, and we cannot ensure or warrant the security
      of any information you provide to us. We do not accept liability for
      unintentional disclosure.
    </Typography>
    <Typography className="app-text" variant="caption" component="p">
      By using the <b>Services</b> or providing information to us, you agree
      that we may communicate with you electronically regarding security,
      privacy, and administrative issues relating to your use of the{' '}
      <b>Services</b>.
    </Typography>
    <DisclaimerSubTitle>Your choices</DisclaimerSubTitle>
    <Typography className="app-text" variant="caption" component="p">
      You can make the following choices with respect to your personal
      information.
    </Typography>
    <Typography className="app-text" variant="caption" component="p">
      <b>Cookies</b>. Most browsers let you remove and/or stop accepting cookies
      from the websites you visit. To do this, follow the instructions in your
      browser’s settings. Many browsers accept cookies by default until you
      change your settings. If you do not accept cookies, however, you may not
      be able to use all functionality of the Services. For more information
      about cookies, including how to see what cookies have been set on your
      browser and how to manage and delete them, visit{' '}
      <DisclaimerLink href="https://www.allaboutcookies.org">
        www.allaboutcookies.org
      </DisclaimerLink>
      .
    </Typography>
    <Typography className="app-text" variant="caption" component="p">
      We use tracking platforms like Google Analytics and Mixpanel to help us
      understand user activity and patterns.
    </Typography>
    <Typography className="app-text" variant="caption" component="p">
      <b>Advertising Choices</b>. Some of our advertising partners are members
      of the Network Advertising Initiative (NAI) and are subject to the
      Self-Regulatory Principles for Online Behavioral Advertising published by
      the Digital Advertising Alliance (DAA). You can obtain more information
      about these companie&apos;s information collection practices, and opt-out
      of receiving interest-based advertising from participating NAI and DAA
      members at{' '}
      <DisclaimerLink href="http://www.networkadvertising.org/managing/opt_out.asp">
        http://www.networkadvertising.org/managing/opt_out.asp
      </DisclaimerLink>{' '}
      and/or the DAA&apos;s website at{' '}
      <DisclaimerLink href="https://optout.aboutads.info">
        optout.aboutads.info
      </DisclaimerLink>
      . Mobile app users may opt out of receiving interest-based advertising in
      mobile apps provided by participating members of the Digital Advertising
      Alliance by installing the AppChoices mobile app, available at{' '}
      <DisclaimerLink href="https://www.youradchoices.com/appchoices">
        https://www.youradchoices.com/appchoices
      </DisclaimerLink>
      . You can also limit collection of your information for interest-based ads
      by blocking third party cookies in your browser settings or using privacy
      plug-ins or ad blocking software that help you block third party cookies.
      In addition, your mobile device settings may provide functionality to
      limit use of the advertising ID associated with your mobile device for
      targeted online advertising purposes. If you opt-out of interest-based
      advertisements, you will still see advertisements online but they may be
      less relevant to you.
    </Typography>
    <Typography className="app-text" variant="caption" component="p">
      Some of the third party advertising companies we work with offer their own
      opt-out options that you can use to limit their use of your information
      for interest-based advertising, such as Google (
      <DisclaimerLink href="https://policies.google.com/privacy">
        info{' '}
      </DisclaimerLink>
      and{' '}
      <DisclaimerLink href="https://adssettings.google.com/authenticated">
        opt-out
      </DisclaimerLink>
      ) and Facebook (
      <DisclaimerLink href="https://www.facebook.com/privacy/explanation">
        info{' '}
      </DisclaimerLink>
      and{' '}
      <DisclaimerLink href="https://www.facebook.com/about/ads">
        opt-out
      </DisclaimerLink>
      ). Please note that we also may work with companies that offer their own
      opt-out mechanisms or do not participate in the opt-out mechanisms
      described above. Even after using these opt-out mechanisms, you may
      receive interest-based advertising from other companies.
    </Typography>
    <DisclaimerSubTitle>Other sites and services</DisclaimerSubTitle>
    <Typography className="app-text" variant="caption" component="p">
      The <b>Services</b> may contain links to other websites and online
      services operated by third parties and our retail partners. These links
      are not an endorsement of, or representation that we are affiliated with,
      any third party. In addition, our content may be included on web pages or
      online services that are not associated with us. We do not control third
      party websites or online services, and we are not responsible for their
      actions. Other websites and services follow different rules regarding the
      collection, use and sharing of your personal information. We encourage you
      to read the privacy policies of the other websites and online services you
      use.
    </Typography>
  </>
);

export default PrivacyPolicyContent;
