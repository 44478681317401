import { PropsWithChildren } from 'react';

import Typography from '@mui/material/Typography';

const DisclaimerSubTitle: React.FC<PropsWithChildren> = ({ children }) => (
  <Typography
    className="app-text"
    variant="body2"
    component="p"
    sx={{ fontWeight: 600, mt: 2 }}
  >
    {children}
  </Typography>
);

export default DisclaimerSubTitle;
