import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';

import CloseIcon from '../icons/CloseIcon';
import Modal from '../shared/Modal';

import { useAnalytics } from '../../AnalyticsProvider';

export interface PhotoModalProps {
  open: boolean;
  onClose: () => void;
  photoURI: string;
}

const PhotoModal: React.FC<PhotoModalProps> = ({ open, onClose, photoURI }) => {
  const analytics = useAnalytics();

  const handleShare = async (photoURI: string) => {
    analytics.trackEvent('photo_share_tapped');

    const res = await fetch(photoURI);
    const blob = await res.blob();

    const data = {
      files: [
        new File([blob], 'furniture.jpg', {
          type: blob.type,
        }),
      ],
    };

    try {
      if (!navigator.canShare(data)) {
        console.error("Can't share");
      }
      await navigator.share(data);

      analytics.trackEvent('photo_shared');
    } catch (err) {
      console.error(err);
    }
  };

  return (
    <Modal
      open={open}
      title="Share Photo"
      delay={0.35}
      left={<Box sx={{ width: 46, height: 46 }} />}
      right={
        <IconButton onClick={onClose}>
          <CloseIcon />
        </IconButton>
      }
    >
      <Box className="preview-container">
        <Box className="preview">
          <img src={photoURI} alt="Snapshot preview" />
        </Box>
      </Box>
      <Box className="share-container">
        <Button
          className="furniture-button"
          variant="contained"
          onClick={() => handleShare(photoURI)}
          disableElevation
        >
          <Typography className="app-text" variant="subtitle1">
            Share
          </Typography>
        </Button>
      </Box>
    </Modal>
  );
};

export default PhotoModal;
