import { Routes, Route } from 'react-router-dom';

import Home from './pages/Home';
import NoModel from './pages/NoModel';
import SignIn from './pages/SignIn';
import TermsAndConditions from './pages/TermsAndConditions';
import Scan from './pages/Scan';
import Furniture from './pages/Furniture';

export const routesTitleMap: Record<string, string> = {
  '/': 'Home',
  '/no-model': 'No Sku',
  '/sign-in': 'Sign in',
  '/terms-and-conditions': 'Terms and conditions',
  '/scan': 'Scan',
  '/furniture-viewer': 'Furniture Viewer',
};

const AppRoutes: React.FC = () => (
  <Routes>
    <Route path="/" element={<Home />} />
    <Route path="/no-model" element={<NoModel />} />
    <Route path="/sign-in" element={<SignIn />} />
    <Route path="/terms-and-conditions" element={<TermsAndConditions />} />
    <Route path="/scan" element={<Scan />} />
    <Route path="/furniture-viewer" element={<Furniture />} />
  </Routes>
);

export default AppRoutes;
