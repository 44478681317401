import { PropsWithChildren } from 'react';

import Typography from '@mui/material/Typography';

const DisclaimerTitle: React.FC<PropsWithChildren> = ({ children }) => (
  <Typography
    className="app-text"
    variant="body1"
    component="p"
    sx={{ fontWeight: 600 }}
  >
    {children}
  </Typography>
);

export default DisclaimerTitle;
