const ScanHint: React.FC = () => (
  <svg
    width="101"
    height="100"
    viewBox="0 0 101 100"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M71.125 55.4234V53.4844"
      stroke="white"
      strokeWidth="4"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M71.125 74.6719V76.5625C71.125 80.0156 68.3281 82.8125 64.875 82.8125H36.75C33.2969 82.8125 30.5 80.0156 30.5 76.5625V14.0625C30.5 10.6094 33.2969 7.8125 36.75 7.8125H64.875C68.3281 7.8125 71.125 10.6094 71.125 14.0625V28.4375V44.8266V40.1563"
      stroke="white"
      strokeWidth="4"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M71.125 66.0156V64.0781"
      stroke="white"
      strokeWidth="4"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M59.875 8.125L57.9465 10.825C57.1956 11.8761 55.9833 12.5 54.6915 12.5H46.3085C45.0167 12.5 43.8044 11.8761 43.0535 10.825L41.125 8.125"
      stroke="white"
      strokeWidth="4"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M77.8797 65.8735C79.2891 67.2828 79.2891 69.5688 77.8797 70.9797L75.1251 73.7344C73.6001 75.2594 71.1266 75.2594 69.6016 73.7344L69.0563 73.1891C67.6469 71.7797 67.6469 69.4938 69.0563 68.0828L72.0188 65.1203C73.4282 63.7109 75.7141 63.7109 77.1251 65.1203L77.8797 65.8735Z"
      stroke="white"
      strokeWidth="4"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M77.8812 55.2828C79.2906 56.6922 79.2906 58.9781 77.8812 60.3891L75.1281 63.1422C74.3953 63.875 73.4015 64.2859 72.3656 64.2859C71.3296 64.2859 70.3359 63.8734 69.6031 63.1422L69.0578 62.5969C67.6484 61.1875 67.6484 58.9016 69.0578 57.4906L72.0203 54.5281C72.6984 53.85 73.6156 53.4703 74.5734 53.4703C75.5312 53.4703 76.45 53.8516 77.1265 54.5281L77.8812 55.2828Z"
      stroke="white"
      strokeWidth="4"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M77.8812 44.6906C79.2906 46.1 79.2906 48.3859 77.8812 49.7969L75.1281 52.55C74.3953 53.2828 73.4015 53.6937 72.3656 53.6937C71.3296 53.6937 70.3359 53.2812 69.6031 52.55L69.0578 52.0047C67.6484 50.5953 67.6484 48.3094 69.0578 46.8984L72.0203 43.9359C72.6984 43.2578 73.6156 42.8781 74.5734 42.8781C75.5312 42.8781 76.45 43.2594 77.1265 43.9359L77.8812 44.6906Z"
      stroke="white"
      strokeWidth="4"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M68.8125 47.1563L69.0625 46.9063L71.125 44.8438V44.8266L72.1094 43.8594L79.375 36.5781C80.1406 35.8125 80.5 34.8281 80.5 33.8281C80.5 32.8266 80.1406 31.8281 79.375 31.0609C78.5469 30.2328 77.4375 29.7812 76.2812 29.7812C75.1094 29.7812 74 30.2328 73.1719 31.0609L71.125 33.125"
      stroke="white"
      strokeWidth="4"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M11.75 90.625L15.6406 86.7344C19.1562 83.2187 21.125 78.4531 21.125 73.4844V58.8281C21.125 55.0594 21.8437 51.3266 23.2422 47.8281C23.9078 46.1656 24.25 44.3906 24.25 42.6V32.8125L23.1094 24.8594C23 24.1094 23.2344 23.3422 23.7187 22.7812C24.2187 22.2031 24.9375 21.875 25.7031 21.875C27.625 21.875 29.3437 22.7187 30.5 24.0625"
      stroke="white"
      strokeWidth="4"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M61.75 82.8125C54.7188 89.8438 47.8172 87.3703 43 92.1875"
      stroke="white"
      strokeWidth="4"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default ScanHint;
