export interface Furniture {
  showOverlay: boolean;
  showTapPrompt: boolean;
  showRepositionPrompt: boolean;
  showResetButton: boolean;
  showButtons: boolean;
  showPhotoPreview: boolean;
  showRecommendations: boolean;
  showProductInfo: boolean;
  photoURI: string;
}

export enum FurnitureActionType {
  onOverlayChange,
  onModelPlaced,
  showRecommendations,
  showProductInfo,
  hideReposition,
  closeRecommendations,
  closeProductInfo,
  closePhoto,
  setPhoto,
  loadNewModel,
}

export type FurnitureActions =
  | {
      type: FurnitureActionType.onOverlayChange;
      shown: boolean;
    }
  | { type: FurnitureActionType.onModelPlaced }
  | { type: FurnitureActionType.showRecommendations }
  | { type: FurnitureActionType.showProductInfo }
  | { type: FurnitureActionType.closeRecommendations }
  | { type: FurnitureActionType.closeProductInfo }
  | {
      type: FurnitureActionType.setPhoto;
      photoUri: string;
    }
  | { type: FurnitureActionType.closePhoto }
  | { type: FurnitureActionType.loadNewModel }
  | { type: FurnitureActionType.hideReposition };

export type FurnitureReducer = (
  state: Readonly<Furniture>,
  action: FurnitureActions
) => Furniture;

const furnitureReducer: FurnitureReducer = (state, action) => {
  switch (action.type) {
    case FurnitureActionType.onOverlayChange:
      return {
        ...state,
        showOverlay: action.shown,
        showTapPrompt: !action.shown,
        showRepositionPrompt: false,
        showResetButton: false,
        showButtons: false,
        showPhotoPreview: false,
        showRecommendations: false,
        showProductInfo: false,
      };

    case FurnitureActionType.onModelPlaced:
      return {
        ...state,
        showTapPrompt: false,
        showRepositionPrompt: true,
        showResetButton: false,
        showButtons: true,
        showPhotoPreview: false,
        showRecommendations: false,
        showProductInfo: false,
      };

    case FurnitureActionType.hideReposition:
      return {
        ...state,
        showTapPrompt: false,
        showRepositionPrompt: false,
        showResetButton: true,
      };

    case FurnitureActionType.showProductInfo:
      return {
        ...state,
        showButtons: false,
        showRepositionPrompt: false,
        showResetButton: false,
        showProductInfo: true,
        showPhotoPreview: false,
        showRecommendations: false,
      };

    case FurnitureActionType.setPhoto:
      return {
        ...state,
        showButtons: false,
        showRepositionPrompt: false,
        showResetButton: false,
        showProductInfo: false,
        showPhotoPreview: true,
        photoURI: `data:image/jpeg;base64,${action.photoUri}`,
        showRecommendations: false,
      };

    case FurnitureActionType.showRecommendations:
      return {
        ...state,
        showButtons: false,
        showRepositionPrompt: false,
        showResetButton: false,
        showProductInfo: false,
        showPhotoPreview: false,
        showRecommendations: true,
      };

    case FurnitureActionType.closeRecommendations:
      return {
        ...state,
        showResetButton: true,
        showButtons: true,
        showRepositionPrompt: false,
        showRecommendations: false,
      };

    case FurnitureActionType.closeProductInfo:
      return {
        ...state,
        showResetButton: true,
        showButtons: true,
        showRepositionPrompt: false,
        showProductInfo: false,
      };

    case FurnitureActionType.closePhoto:
      return {
        ...state,
        showResetButton: true,
        showButtons: true,
        showRepositionPrompt: false,
        showPhotoPreview: false,
      };

    case FurnitureActionType.loadNewModel:
      return {
        ...state,
        showRecommendations: false,
        showTapPrompt: true,
        showRepositionPrompt: false,
      };

    default:
      return { ...state };
  }
};

export default furnitureReducer;
