import { useMemo } from 'react';

import { useSearchParams } from 'react-router-dom';

import panorama from '../assets/images/ashley_panorama_image.png';

const useSceneHtml = (debug: boolean) => {
  const [searchParams] = useSearchParams();

  const sceneHtml = useMemo(() => {
    const landingPageAttr = [
      'textColor: #363534;',
      'backgroundColor: #fff;',
      `url: ${window.location.origin}?${searchParams.toString()};`,
      'textShadow: false;',
      'sceneOrbitIdle: none;',
    ];

    if (searchParams.has('sku')) {
      const modelSrc = `${process.env.REACT_APP_MODEL_HOST}/${searchParams.get(
        'sku'
      )}.glb`;

      landingPageAttr.push(`mediaSrc: ${modelSrc};`);
      landingPageAttr.push(`sceneEnvMap: ${panorama};`);
      landingPageAttr.push(`sceneEnvMap: ${panorama};`);
    }

    const allowedDevices = debug ? 'any' : 'mobile';

    return `
      <a-scene
        id="a-scene"
        coaching-overlay="disablePrompt: true;"
        landing-page="${landingPageAttr.join(' ')}"
        xrextras-gesture-detector
        xrextras-loading
        xrextras-runtime-error
        gltf-model="dracoDecoderPath: https://cdn.8thwall.com/web/aframe/draco-decoder/"
        renderer="colorManagement: true"
        configure-scene
        xrweb="allowedDevices: ${allowedDevices}; scale: absolute;"
      >
        <a-camera
          id="camera"
          position="0 8 8"
          raycaster="objects: .cantap"
          cursor="fuse: false; rayOrigin: mouse;"
        ></a-camera>

        <a-entity
          xr-light
          light="
            type: directional;
            castShadow: true;
            shadowMapHeight:2048;
            shadowMapWidth:2048;
            shadowCameraTop: 20;
            shadowCameraBottom: -20;
            shadowCameraLeft: -20;
            shadowCameraRight: 20;
            target: #model;
            shadowRadius: 3
          "
          xrextras-attach="target: model; offset: 0 15 0;"
          shadow
        ></a-entity>

        <a-light
          type="ambient"
          xr-light="max: 0.5"
        ></a-light>

        <a-entity
          id="cursor-target"
          position="0 0 0"
          scale="0.0001 0.0001 0.0001"
        >
          <a-circle
            rotation="-90 0 0"
            position="0 0 0"
            radius="0.3"
            material="color: #FFFFFF; opacity: 0.25;"
          ></a-circle>

          <a-ring
            rotation="-90 0 0"
            position="0 0.001 0"
            radius-inner="0.21"
            radius-outer="0.23"
            material="color: #FFFFFF; opacity: 0.85;"
          ></a-ring>

          <a-circle
            rotation="-90 0 0"
            position="0 0.001 0"
            radius="0.1"
            material="color: #FFFFFF; opacity: 0.85;"
          ></a-circle>
        </a-entity>

        <a-entity
          id="model"
          class="cantap"
          xrextras-hold-drag="rise-height: 0.25"
          xrextras-two-finger-rotate
          shadow="receive: false"
          scale="0.0001 0.0001 0.0001"
        ></a-entity>

        <a-plane
          id="ground"
          class="cantap"
          rotation="-90 0 0"
          width="14"
          height="14"
          material="shader: shadow" 
          shadow
        ></a-plane>
      </a-scene>
      `;
  }, [debug, searchParams]);

  return sceneHtml;
};

export default useSceneHtml;
