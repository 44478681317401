import { PropsWithChildren } from 'react';

import Typography from '@mui/material/Typography';

const DisclaimerLink: React.FC<PropsWithChildren & { href: string }> = ({
  children,
  href,
}) => (
  <Typography
    variant="caption"
    component="a"
    href={href}
    className="terms-link"
    target="_blank"
    rel="noopener noreferrer"
  >
    {children}
  </Typography>
);

export default DisclaimerLink;
