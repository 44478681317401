const RestartIcon: React.FC = () => (
  <svg
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M16 5.33332C18.8 5.33332 21.4667 6.39999 23.4667 8.39999C27.6 12.5333 27.6 19.3333 23.4667 23.4667C21.0667 26 17.7333 26.9333 14.5333 26.5333L15.2 23.8667C17.4667 24.1333 19.8667 23.3333 21.6 21.6C24.6667 18.5333 24.6667 13.4667 21.6 10.2667C20.1333 8.79999 18 7.99999 16 7.99999V14.1333L9.33333 7.46665L16 0.799988V5.33332ZM8.4 23.4667C4.93333 20 4.4 14.6667 6.8 10.5333L8.8 12.5333C7.33333 15.4667 7.86666 19.2 10.4 21.6C11.0667 22.2667 11.8667 22.8 12.8 23.2L12 25.8667C10.6667 25.3333 9.46666 24.5333 8.4 23.4667Z"
      fill="white"
    />
  </svg>
);

export default RestartIcon;
