const TapIconIllustration = () => (
  <svg viewBox="0 0 59 71" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M56.9998 63.1111L53.6324 55.8776C53.6324 55.8776 55.3784 51.7619 54.8796 50.0159C54.256 47.3969 53.5077 42.6576 51.5122 37.4195C49.3921 31.932 48.1449 29.6871 44.9023 29.6871C44.0292 29.6871 32.5553 32.3062 32.1812 32.3062C30.8093 32.3062 29.8116 31.6826 29.4374 30.8096C27.5667 26.6939 24.0746 19.3356 24.0746 19.3356C24.0746 19.3356 22.4533 16.8413 20.3331 16.8413C20.0837 16.8413 19.7095 16.8413 19.3354 16.966C16.7163 17.839 17.5893 21.5805 17.5893 21.5805L27.1925 49.1429C27.1925 49.1429 25.5712 48.8935 23.451 48.8935C21.3308 48.8935 18.7118 49.1429 16.2174 50.0159C11.2288 51.7619 10.8546 53.8821 11.4782 55.6282C11.7277 56.5012 12.6007 56.7506 13.7231 56.7506C27.1925 55.8776 38.0428 60.991 38.417 61.3651C38.9159 61.864 42.1585 68.9728 42.1585 68.9728"
      stroke="white"
      strokeWidth="3"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M15.7194 30.0615C12.2273 28.3155 9.98242 24.6987 9.98242 20.583C9.98242 14.7214 14.7217 9.85742 20.708 9.85742C26.6944 9.85742 31.4337 14.5966 31.4337 20.583C31.4337 21.8302 31.1842 23.0774 30.8101 24.3245"
      stroke="white"
      strokeWidth="3"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M19.2109 39.1655C9.60771 38.4172 2 30.4354 2 20.5828C2 10.356 10.356 2 20.5828 2C30.8095 2 39.2902 10.2313 39.2902 20.5828C39.2902 22.5782 39.0408 24.449 38.4172 26.195"
      stroke="white"
      strokeWidth="3"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default TapIconIllustration;
