import { createContext, useRef, PropsWithChildren, useContext } from 'react';
import { useSearchParams } from 'react-router-dom';

import { v4 as uuidv4 } from 'uuid';

export const CustomerIdContext = createContext<string>('');

export const useCustomerId = () => useContext(CustomerIdContext);

export const CUSTOMER_ID_KEY = 'customerid' as const;
export const CUSTOMER_SESSION_KEY = 'customersession' as const;

const CustomerIdProvider: React.FC<PropsWithChildren> = ({ children }) => {
  const [searchParams] = useSearchParams();

  const id = useRef(
    searchParams.get(CUSTOMER_ID_KEY) ||
      searchParams.get(CUSTOMER_SESSION_KEY) ||
      uuidv4()
  );

  return (
    <CustomerIdContext.Provider value={id.current}>
      {children}
    </CustomerIdContext.Provider>
  );
};

export default CustomerIdProvider;
