import { motion } from 'framer-motion';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import TapIconIllustration from './TapIconIllustration';

const TapPrompt: React.FC = () => (
  <Box
    className="tap-prompt"
    key="tap-prompt"
    component={motion.div}
    initial={{ opacity: 0 }}
    animate={{ opacity: 1 }}
    exit={{ opacity: 0 }}
    transition={{ ease: 'easeIn', duration: 0.35 }}
  >
    <Box className="tap-prompt-info">
      <Typography className="tap-prompt-title app-text" variant="subtitle1">
        TAP TO PLACE
      </Typography>
      <Typography
        className="app-text"
        variant="body2"
        sx={{ lineHeight: 'normal', letterSpacing: '-0.408px', mt: '8px' }}
      >
        Find an empty spot on the ground and tap the marker to place the
        product.
      </Typography>
    </Box>
    <Box className="tap-prompt-icon">
      <TapIconIllustration />
    </Box>
  </Box>
);

export default TapPrompt;
