import { AFRAMEElement } from './types';

const raycaster = new window.THREE.Raycaster();
const screenPosition = new window.THREE.Vector2(0, 0);

const modelHost = process.env.REACT_APP_MODEL_HOST;

const component = {
  el: {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    addEventListener: (name: unknown, cb: unknown) => null,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    removeEventListener: (name: unknown, cb: unknown) => null,
    sceneEl: {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      emit: (evt: string, data: unknown) => null,
      camera: null,
    },
  },
  schema: {
    sku: { type: 'string', default: '' },
  },
  data: {
    sku: '',
  },
  init() {
    const model = document.getElementById('model') as AFRAMEElement;
    const ground = document.getElementById('ground') as AFRAMEElement;
    const cursor = document.getElementById('cursor-target') as AFRAMEElement;

    let scaleEstimated = false;
    let modelLoaded = false;
    let modelPlaced = false;

    const onModelLoaded = () => {
      modelLoaded = true;
      this.el.sceneEl.emit('model-loaded', {});
    };

    const onModelError = () => this.el.sceneEl.emit('model-error', {});

    const onOverlayShow = () => {
      if (model) model.object3D.scale.set(0.0001, 0.0001, 0.0001);
      if (cursor) cursor.object3D.scale.set(0.0001, 0.0001, 0.0001);

      modelPlaced = false;
      scaleEstimated = false;

      this.el.sceneEl.emit('furniture-overlay-changed', { shown: true });
    };

    const onOverlayHide = () => {
      modelPlaced = false;
      scaleEstimated = true;

      if (cursor) cursor.object3D.scale.set(1.0, 1.0, 1.0);

      this.el.sceneEl.emit('furniture-overlay-changed', { shown: false });
    };

    const onTapGround = () => {
      if (!scaleEstimated || !model || !cursor || !modelLoaded || modelPlaced)
        return;

      model.object3D.position.x = cursor.object3D.position.x;
      model.object3D.position.y = cursor.object3D.position.y;
      model.object3D.position.z = cursor.object3D.position.z;

      cursor.object3D.scale.set(0.0001, 0.0001, 0.0001);
      model.object3D.scale.set(1.0, 1.0, 1.0);

      if (!modelPlaced) {
        modelPlaced = true;

        this.el.sceneEl.emit('model-placed', {});
      }
    };

    const resetScene = () => {
      XR8.XrController.recenter();
      onOverlayShow();
    };

    const onAttach = () => {
      if (model) {
        model.object3D.scale.set(0.0001, 0.0001, 0.0001);

        model.addEventListener('model-error', onModelError);
        model.addEventListener('model-loaded', onModelLoaded);

        model.setAttribute('gltf-model', `${modelHost}/${this.data.sku}.glb`);
      }

      if (ground) {
        const [child] = ground.object3D.children;
        const { height } = (
          (child as THREE.Mesh).geometry as THREE.PlaneGeometry
        ).parameters;

        ground.object3D.position.z = height * 0.5;

        ground.addEventListener('click', onTapGround);
      }

      this.el.addEventListener('coaching-overlay.show', onOverlayShow);
      this.el.addEventListener('coaching-overlay.hide', onOverlayHide);

      this.el.addEventListener('reset-scene', resetScene);

      XR8.XrController.recenter();
      onOverlayShow();
    };

    const onUpdate = () => {
      if (!scaleEstimated || modelPlaced || !ground || !cursor) return;

      const threeCamera = this.el.sceneEl.camera as unknown;
      raycaster.setFromCamera(screenPosition, threeCamera as THREE.Camera);

      const results = raycaster.intersectObject(ground.object3D, true);
      if (results.length > 0) {
        const [intersection] = results;
        cursor.object3D.position.lerp(intersection.point, 0.3);
      }
    };

    const onDetach = () => {
      scaleEstimated = false;
      modelLoaded = false;
      modelPlaced = false;

      if (model) {
        model.object3D.scale.set(0.0001, 0.0001, 0.0001);

        model.removeEventListener('model-error', onModelError);
        model.removeEventListener('model-loaded', onModelLoaded);

        model.removeAttribute('gltf-model');
      }

      if (ground) ground.removeEventListener('click', onTapGround);

      this.el.removeEventListener('coaching-overlay.show', onOverlayShow);
      this.el.removeEventListener('coaching-overlay.hide', onOverlayHide);

      this.el.removeEventListener('reset-scene', resetScene);
    };

    const onxrloaded = () => {
      XR8.addCameraPipelineModule({
        name: 'furnitureScene',
        onAttach,
        onUpdate,
        onDetach,
      });
    };

    XR8 ? onxrloaded() : window.addEventListener('xrloaded', onxrloaded);
  },
  remove() {
    XR8.removeCameraPipelineModule('furnitureScene');
  },
};

export default component;
