const CloseIcon: React.FC = () => (
  <svg
    width="30"
    height="30"
    viewBox="0 0 30 30"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.93059 7.92912C7.54006 8.31965 7.54006 8.95281 7.93059 9.34334L13.5875 15.0002L7.93062 20.6571C7.5401 21.0476 7.5401 21.6808 7.93062 22.0713C8.32114 22.4618 8.95431 22.4618 9.34483 22.0713L15.0017 16.4144L20.6585 22.0713C21.049 22.4618 21.6822 22.4618 22.0727 22.0713C22.4632 21.6807 22.4632 21.0476 22.0727 20.657L16.4159 15.0002L22.0728 9.34338C22.4633 8.95286 22.4633 8.31969 22.0728 7.92917C21.6822 7.53864 21.0491 7.53864 20.6585 7.92917L15.0017 13.586L9.3448 7.92912C8.95428 7.5386 8.32111 7.5386 7.93059 7.92912Z"
      fill="white"
    />
  </svg>
);

export default CloseIcon;
