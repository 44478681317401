import { motion } from 'framer-motion';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import MoveIcon from '../icons/MoveIcon';
import RotateIcon from '../icons/RotateIcon';

const RepositionPrompt: React.FC = () => (
  <Box
    className="reposition-prompt place-prompt"
    component={motion.div}
    initial={{ opacity: 0 }}
    animate={{ opacity: 1 }}
    exit={{ opacity: 0 }}
    transition={{ ease: 'easeIn', duration: 0.35 }}
    key="place-prompt"
  >
    <Typography
      className="reposition-prompt-title app-text"
      variant="subtitle1"
    >
      MOVE & ROTATE PRODUCT
    </Typography>

    <Box className="reposition-prompt-info">
      <Box className="reposition-icon">
        <MoveIcon />
        <Typography
          className="app-text"
          variant="body2"
          sx={{ fontWeight: 600, mt: '8px' }}
        >
          Move
        </Typography>
        <Typography className="app-text" variant="body2">
          Press and hold
          <br />
          to re-position
        </Typography>
      </Box>
      <Box className="reposition-icon">
        <RotateIcon />
        <Typography
          className="app-text"
          variant="body2"
          sx={{ fontWeight: 600, mt: '8px' }}
        >
          Rotate
        </Typography>
        <Typography className="app-text" variant="body2">
          Use two fingers
          <br />
          to rotate
        </Typography>
      </Box>
    </Box>
  </Box>
);

export default RepositionPrompt;
