import { useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import Cookies from 'js-cookie';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';

import Checkbox from '../components/shared/Checkbox';
import CloseButton from '../components/shared/CloseButton';
import CloseIcon from '../components/icons/CloseIcon';
import FileCheckIcon from '../components/icons/FileCheckIcon';
import Modal from '../components/shared/Modal';
import PrivacyPolicyContent from '../components/terms/PrivacyPolicyContent';
import Screen from '../components/shared/Screen';
import TermsAndConditionsContent from '../components/terms/TermsAndConditionsContent';
import VRIllustration from '../components/terms/VRIllustration';

import { useAnalytics } from '../AnalyticsProvider';

import { AFRAMEElement } from '../aframe/types';

import './TermsAndConditions.scss';

const TermsAndConditions: React.FC = () => {
  const [displaying, setDisplaying] = useState<null | 'terms' | 'privacy'>(
    null
  );
  const [checked, setChecked] = useState(false);

  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  const analytics = useAnalytics();

  const onContinue = async () => {
    analytics.trackEvent('terms_continue_tapped');

    if (!checked) return;

    Cookies.set('tc-signed', 'true', {
      domain: window.location.hostname,
      path: '/',
      expires: 60,
    });

    analytics.trackEvent('terms_accepted');
    analytics.set({
      has_accepted_terms: true,
      accepted_terms_timestamp: Date.now(),
    });

    navigate({
      pathname: '/scan',
      search: searchParams.toString(),
    });
  };

  useEffect(() => {
    const scene = document.getElementById('a-scene') as AFRAMEElement;
    scene?.emit('resume-scene');

    if (Cookies.get('tc-signed') === 'true') {
      navigate({
        pathname: '/scan',
        search: searchParams.toString(),
      });
    }
  }, [navigate, searchParams]);

  useEffect(() => {
    if (Cookies.get('tc-signed') === 'true') {
      navigate({
        pathname: '/scan',
        search: searchParams.toString(),
      });
    }
  }, [navigate, searchParams]);

  return (
    <Screen className="terms-and-conditions">
      {!displaying && <CloseButton />}

      <Modal
        open
        full={!!displaying}
        title="Terms & Conditions"
        left={
          <IconButton>
            <FileCheckIcon />
          </IconButton>
        }
        right={
          displaying ? (
            <IconButton onClick={() => setDisplaying(null)}>
              <CloseIcon />
            </IconButton>
          ) : null
        }
      >
        {!displaying && (
          <Box className="terms-illustration">
            <VRIllustration />
          </Box>
        )}

        {displaying === 'privacy' && (
          <Box className="terms-text">
            <PrivacyPolicyContent />
          </Box>
        )}

        {displaying === 'terms' && (
          <Box className="terms-text">
            <TermsAndConditionsContent
              onClickPolicy={() => setDisplaying('privacy')}
            />
          </Box>
        )}

        <Box className="terms-footer">
          <Button
            className="continue-button"
            variant="contained"
            disableElevation
            disabled={!checked}
            onClick={onContinue}
          >
            <Typography
              variant="subtitle2"
              sx={{ fontFamily: 'Poppins', lineHeight: '20px' }}
            >
              Continue
            </Typography>
          </Button>

          <Box className="terms-notice">
            <Checkbox
              checked={checked}
              setChecked={(checked) => setChecked(checked)}
              label={
                <Typography
                  className="app-text"
                  variant="body2"
                  sx={{ lineHeight: '24px' }}
                >
                  I agree to the full terms and conditions.
                </Typography>
              }
            />
          </Box>

          <Button
            className="more-button"
            variant="text"
            disableRipple
            disableElevation
            onClick={() => {
              analytics.trackEvent('terms_and_conditions_read');
              setDisplaying('terms');
            }}
          >
            <Typography variant="subtitle2">
              Read full Terms & Conditions
            </Typography>
          </Button>
          <Button
            className="more-button"
            variant="text"
            disableRipple
            disableElevation
            onClick={() => {
              analytics.trackEvent('privacy_policy_read');
              setDisplaying('privacy');
            }}
          >
            <Typography variant="subtitle2">
              Read full Privacy Policy
            </Typography>
          </Button>
        </Box>
      </Modal>
    </Screen>
  );
};

export default TermsAndConditions;
