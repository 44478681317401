import Box from '@mui/material/Box';
import MuiCheckbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';

import './Checkbox.scss';

export interface CheckboxProps {
  checked: boolean;
  setChecked: (value: boolean) => void;
  label: React.ReactNode;
}

const Checkbox: React.FC<CheckboxProps> = ({ checked, setChecked, label }) => (
  <FormControlLabel
    className="app-checkbox-control"
    control={
      <Box className="app-checkbox">
        <MuiCheckbox
          disableRipple
          checked={checked}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
            setChecked(event.target.checked)
          }
        />
      </Box>
    }
    label={label}
  />
);

export default Checkbox;
