import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';

import CloseIcon from '../icons/CloseIcon';
import Modal from '../shared/Modal';
import TagIcon from '../icons/TagIcon';

import sofaInfo from '../../assets/images/chair.png';

import { useAnalytics } from '../../AnalyticsProvider';

import './ProductInfoModal.scss';

const fallbackURL = 'https://ashleyhomestore.ca/';

export interface ProductInfoModalProps {
  open: boolean;
  onClose: () => void;
  sku: string;
  returnURL?: string | null;
}

const ProductInfoModal: React.FC<ProductInfoModalProps> = ({
  open,
  onClose,
  sku,
  returnURL,
}) => {
  const analytics = useAnalytics();

  return (
    <Modal
      open={open}
      title="Product Info"
      className="product-info-modal"
      height={50}
      left={<TagIcon />}
      right={
        <IconButton onClick={onClose}>
          <CloseIcon />
        </IconButton>
      }
    >
      <Box className="product-thumbnail">
        <img src={sofaInfo} alt="Sofa info" />
      </Box>
      <Box className="product-info">
        <Typography
          className="app-text"
          sx={{ fontSize: '1.125rem', fontWeight: 400 }}
        >
          {sku}
        </Typography>
        <Typography
          className="app-text"
          sx={{ fontSize: '1.125rem', fontWeight: 700 }}
        >
          {sku}
        </Typography>

        <Button
          className="furniture-button"
          variant="contained"
          disableElevation
          href={returnURL ?? fallbackURL}
          rel="noopener noreferrer"
          LinkComponent="a"
          onClick={() => analytics.trackEvent('pdp_btn_tapped')}
        >
          <Typography className="app-text" variant="subtitle1">
            View Product Page
          </Typography>
        </Button>
      </Box>
    </Modal>
  );
};

export default ProductInfoModal;
