import { useEffect, useState } from 'react';

import { useNavigate, useSearchParams } from 'react-router-dom';
import Cookies from 'js-cookie';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import InputBase from '@mui/material/InputBase';
import InputLabel from '@mui/material/InputLabel';
import Typography from '@mui/material/Typography';

import Checkbox from '../components/shared/Checkbox';
import CloseButton from '../components/shared/CloseButton';
import CloseIcon from '../components/icons/CloseIcon';
import ErrorIcon from '../components/icons/ErrorIcon';
import FileCheckIcon from '../components/icons/FileCheckIcon';
import Modal from '../components/shared/Modal';
import PrivacyPolicyContent from '../components/terms/PrivacyPolicyContent';
import Screen from '../components/shared/Screen';
import TermsAndConditionsContent from '../components/terms/TermsAndConditionsContent';

import { useAnalytics } from '../AnalyticsProvider';

import { AFRAMEElement } from '../aframe/types';

import './SignIn.scss';

const ERROR_MESSAGE = 'Please enter a valid email address.' as const;

const SignIn: React.FC = () => {
  const [email, setEmail] = useState('');
  const [error, setError] = useState('');
  const [checked, setChecked] = useState(true);
  const [displaying, setDisplaying] = useState<null | 'terms' | 'privacy'>(
    null
  );

  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  const analytics = useAnalytics();

  const validateEmail = (email: string) =>
    !!email.match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );

  const navigateToScan = () => {
    if (checked) {
      Cookies.set('tc-signed', 'true', {
        domain: window.location.hostname,
        path: '/',
        expires: 1,
      });

      analytics.trackEvent('terms_accepted');
      analytics.set({
        has_accepted_terms: true,
        accepted_terms_timestamp: Date.now(),
      });
    }

    if (validateEmail(email)) {
      searchParams.set('email', email);
      localStorage.setItem('email', email);
    }

    navigate({
      pathname: '/scan',
      search: searchParams.toString(),
    });
  };

  const onSubmit = (evt: React.FormEvent<HTMLFormElement>) => {
    evt.preventDefault();

    analytics.trackEvent('sign_in_continue_tapped');

    if (!validateEmail(email)) {
      setError(ERROR_MESSAGE);

      return;
    }

    navigateToScan();
  };

  useEffect(() => {
    const scene = document.getElementById('a-scene') as AFRAMEElement;
    scene?.emit('resume-scene');

    if (Cookies.get('tc-signed') === 'true') {
      navigate({
        pathname: '/scan',
        search: searchParams.toString(),
      });
    }
  }, [navigate, searchParams]);

  return (
    <Screen className="sign-in">
      {!displaying && (
        <>
          <CloseButton />

          <Box className="sign-in-content">
            <Typography className="app-text" variant="h6">
              What is the best email to get in touch with you?
            </Typography>

            <Box component="form" className="sign-in-form" onSubmit={onSubmit}>
              <FormControl
                variant="standard"
                className="email-control"
                error={!!error}
              >
                <InputLabel shrink htmlFor="email-input">
                  <Typography className="app-text" variant="subtitle1">
                    Email
                  </Typography>
                </InputLabel>

                <InputBase
                  className="email-input"
                  id="email-input"
                  value={email}
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    setEmail(event.target.value);
                    setError('');
                  }}
                  onBlur={() => {
                    if (!validateEmail(email)) {
                      setError(ERROR_MESSAGE);
                      return;
                    }

                    analytics.trackEvent('email_filled', { email });
                  }}
                  placeholder="email"
                  endAdornment={
                    error ? (
                      <InputAdornment position="end">
                        <ErrorIcon />
                      </InputAdornment>
                    ) : null
                  }
                />

                {error && (
                  <FormHelperText className="email-helper-text" component="div">
                    <Typography className="app-text" variant="subtitle2">
                      {error}
                    </Typography>
                  </FormHelperText>
                )}
              </FormControl>

              <Button
                className="furniture-button"
                variant="contained"
                disableElevation
                disabled={!checked || !validateEmail(email)}
                type="submit"
              >
                <Typography className="app-text" variant="body2">
                  Continue
                </Typography>
              </Button>
            </Box>

            <Box className="sign-in-tc">
              <Checkbox
                checked={checked}
                setChecked={(checked) => setChecked(checked)}
                label={
                  <Typography className="app-text" variant="body2">
                    I certify that I have read and agree to the full{' '}
                    <a
                      href="#"
                      onClick={(evt) => {
                        evt.preventDefault();

                        analytics.trackEvent('terms_and_conditions_read');
                        setDisplaying('terms');
                      }}
                    >
                      Terms & Conditions
                    </a>{' '}
                    and{' '}
                    <a
                      href="#"
                      onClick={(evt) => {
                        evt.preventDefault();

                        analytics.trackEvent('privacy_policy_read');
                        setDisplaying('privacy');
                      }}
                    >
                      Privacy Policy
                    </a>
                  </Typography>
                }
              />
            </Box>
          </Box>

          <Button
            disabled={!checked}
            className="skip"
            onClick={() => {
              analytics.trackEvent('terms_skipped');
              navigateToScan();
            }}
          >
            <Typography className="app-text" variant="body2">
              Skip
            </Typography>
          </Button>
        </>
      )}

      {displaying && (
        <Modal
          open
          full
          title={
            displaying === 'terms' ? 'Terms & Conditions' : 'Privacy Policy'
          }
          left={
            <IconButton>
              <FileCheckIcon />
            </IconButton>
          }
          right={
            <IconButton onClick={() => setDisplaying(null)}>
              <CloseIcon />
            </IconButton>
          }
        >
          <Box className="terms-text">
            {displaying === 'privacy' && <PrivacyPolicyContent />}
            {displaying === 'terms' && (
              <TermsAndConditionsContent
                onClickPolicy={() => setDisplaying('privacy')}
              />
            )}
          </Box>

          <Button
            className="terms-done"
            variant="contained"
            disableElevation
            onClick={() => setDisplaying(null)}
          >
            <Typography variant="body2">Done</Typography>
          </Button>
        </Modal>
      )}
    </Screen>
  );
};

export default SignIn;
