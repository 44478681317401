import IconButton from '@mui/material/IconButton';

import CloseIcon from '../icons/CloseIcon';

import { useAnalytics } from '../../AnalyticsProvider';

import './CloseButton.scss';

const CloseButton: React.FC = () => {
  const analytics = useAnalytics();

  return (
    <IconButton
      className="close-button"
      sx={{ position: 'absolute', top: '16px', right: '16px' }}
      onClick={() => {
        analytics.trackEvent(
          'close_btn_tapped',
          {},
          {
            send_immediately: true,
          },
          () => window.close()
        );
      }}
    >
      <CloseIcon />
    </IconButton>
  );
};

export default CloseButton;
