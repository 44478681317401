import { motion } from 'framer-motion';

import Box from '@mui/material/Box';

import ARButton from './ARButton';
import CameraIcon from '../icons/CameraIcon';
import SofaIcon from '../icons/SofaIcon';
// import TagIcon from './TagIcon';

export interface ARButtonsProps {
  onClickCamera: () => void;
  onClickSofa: () => void;
  onClickTag: () => void;
}

const ARButtons: React.FC<ARButtonsProps> = ({
  onClickCamera,
  onClickSofa,
  // onClickTag,
}) => (
  <Box
    className="ar-buttons"
    component={motion.div}
    initial={{ y: '100%' }}
    animate={{ y: '0%' }}
    exit={{ y: '100%' }}
    transition={{ ease: 'easeIn', duration: 0.35 }}
    key="ar-buttons"
  >
    {/* <ARButton onClick={onClickTag}>
      <TagIcon />
    </ARButton> */}
    <ARButton onClick={onClickCamera}>
      <CameraIcon />
    </ARButton>
    <ARButton onClick={onClickSofa}>
      <SofaIcon />
    </ARButton>
  </Box>
);

export default ARButtons;
