import { useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import Cookies from 'js-cookie';

import { CUSTOMER_ID_KEY } from '../CustomerIdProvider';

const Home: React.FC = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  useEffect(() => {
    const goToNoModelScreen = () =>
      navigate({
        pathname: '/no-model',
        search: searchParams.toString(),
      });

    const doesModelExist = async (sku: string) => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_MODEL_HOST}/${sku}.glb`,
          {
            method: 'HEAD',
          }
        );

        return response.ok;
      } catch (err) {
        return false;
      }
    };

    const getInitialPath = () => {
      const customerId = searchParams.get(CUSTOMER_ID_KEY);

      return Cookies.get('tc-signed') !== 'true'
        ? !customerId
          ? '/sign-in'
          : '/terms-and-conditions'
        : Cookies.get('scanned') !== 'true'
        ? '/scan'
        : '/furniture-viewer';
    };

    const sku = searchParams.get('sku');
    if (!sku) {
      goToNoModelScreen();
      return;
    }

    (async () => {
      const exist = await doesModelExist(sku);
      if (!exist) {
        goToNoModelScreen();
        return;
      }

      navigate({
        pathname: getInitialPath(),
        search: searchParams.toString(),
      });
    })();
  }, [navigate, searchParams]);

  return <></>;
};

export default Home;
