import 'aframe-fps-counter-component';

import { useEffect, useState, PropsWithChildren } from 'react';

import useSceneHTML from './useSceneHtml';
import configureSceneComponent from './configureSceneComponent';
import furnitureSceneComponent from './furnitureSceneComponent';

export interface AFrameComponent {
  name: string;
  value: object;
}

const registeredComponents = new Set();
const registerComponents = (components: AFrameComponent[]) =>
  components.forEach(({ name, value }) => {
    if (registeredComponents.has(name)) {
      return;
    }
    registeredComponents.add(name);
    AFRAME.registerComponent(name, value);
  });

const debug = !process.env.NODE_ENV || process.env.NODE_ENV === 'development';

const ARScene: React.FC<PropsWithChildren> = ({ children }) => {
  const [loaded, setLoaded] = useState(false);

  const sceneHtml = useSceneHTML(debug);

  useEffect(() => {
    const onXRLoaded = () => setLoaded(true);

    registerComponents([
      { name: 'configure-scene', value: configureSceneComponent },
      { name: 'furniture-scene', value: furnitureSceneComponent },
    ]);

    // Attach the scene to the body
    const html = document.getElementsByTagName('html')[0];
    const origHtmlClass = html.className;
    document.body.insertAdjacentHTML('beforeend', sceneHtml);

    const scene = document.getElementById('a-scene');
    scene?.addEventListener('xr-loaded', onXRLoaded);

    // Cleanup
    return () => {
      scene?.removeEventListener('xr-loaded', onXRLoaded);

      const ascene = document.getElementsByTagName('a-scene')[0];
      if (ascene && ascene.parentNode) {
        ascene.parentNode.removeChild(ascene);
      }
      html.className = origHtmlClass;

      setLoaded(false);
    };
  }, [sceneHtml]);

  if (!loaded) return null;
  return (
    <>
      <video id="camera-feed" muted playsInline controls={false}></video>
      {children}
    </>
  );
};

export default ARScene;
