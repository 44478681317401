import { useEffect } from 'react';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import CloseButton from '../components/shared/CloseButton';
import Modal from '../components/shared/Modal';
import NoModelIcon from '../components/icons/NoModelIcon';
import Screen from '../components/shared/Screen';

import NoModelIllustration from '../assets/images/no-model.png';

import { AFRAMEElement } from '../aframe/types';

import { useAnalytics } from '../AnalyticsProvider';

import './NoModel.scss';

const NoModel: React.FC = () => {
  const analytics = useAnalytics();

  useEffect(() => {
    analytics.trackEvent('error_screen');

    const scene = document.getElementById('a-scene') as AFRAMEElement;
    scene?.emit('resume-scene');
  }, [analytics]);

  return (
    <Screen className="no-model">
      <CloseButton />

      <Modal open title="SKU Unavailable" left={<NoModelIcon />}>
        <Box className="no-model-hint">
          <Typography
            className="app-text"
            variant="body2"
            sx={{ fontWeight: 500 }}
          >
            This product is unavailable for AR
            <br />
            viewing at the moment.
          </Typography>
        </Box>

        <Box className="no-model-illustration">
          <img src={NoModelIllustration} alt="No model illustration" />
        </Box>
      </Modal>
    </Screen>
  );
};

export default NoModel;
