import { useEffect, useState } from 'react';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';

import CloseIcon from '../icons/CloseIcon';
import Modal from '../shared/Modal';
import RecommendationCard from './RecommendationCard';
import SofaIcon from '../icons/SofaIcon';

import './RecommendationsModal.scss';

export type RecommendationsCategory = 'similar' | 'complementary';

export interface RecommendationsModalProps {
  open: boolean;
  onClose: () => void;
  onChangeSku: (sku: string, category: RecommendationsCategory) => void;
  sku: string;
}

export type GetRecommendationsResponse = {
  complementary: string[];
  similar: string[];
};

const API_HOST = process.env.REACT_APP_API_HOST;

const RecommendationsModal: React.FC<RecommendationsModalProps> = ({
  open,
  onClose,
  sku,
  onChangeSku,
}) => {
  const [category, setCategory] = useState<RecommendationsCategory>('similar');
  const [recommendations, setRecommendations] =
    useState<GetRecommendationsResponse | null>(null);

  useEffect(() => {
    if (!open) return;

    const getRecommendations =
      async (): Promise<GetRecommendationsResponse | null> => {
        const response = await fetch(
          `${API_HOST}/recommendations/123456?sku=${sku}`
        );

        if (!response.ok) {
          return null;
        }

        return await response.json();
      };

    (async () => {
      const data = await getRecommendations();
      setRecommendations(data);
    })();
  }, [open, sku]);

  return (
    <Modal
      open={open}
      title="Product Recommendations"
      className="recommendations-modal"
      height={32}
      left={<SofaIcon />}
      right={
        <IconButton onClick={onClose}>
          <CloseIcon />
        </IconButton>
      }
    >
      <Box className="recommendations-section">
        {recommendations === null ? (
          <CircularProgress size={60} />
        ) : (
          <Box className="recommendations-carousel">
            {category === 'similar' &&
              recommendations.similar.map((sku) => (
                <RecommendationCard
                  key={`similar-${sku}`}
                  onSelect={() => onChangeSku(sku, category)}
                  sku={sku}
                />
              ))}

            {category === 'complementary' &&
              recommendations.complementary.map((sku) => (
                <RecommendationCard
                  key={`complementary-${sku}`}
                  onSelect={() => onChangeSku(sku, category)}
                  sku={sku}
                />
              ))}
          </Box>
        )}
      </Box>
      <Box className="recommendations-buttons">
        <Button
          variant="contained"
          className="recommendation-button"
          disableElevation
          disableFocusRipple
          disableRipple
          sx={{
            backgroundColor:
              category === 'similar' ? '#1F1F1F' : 'rgba(31, 31, 31, 0.20)',
            '&:hover': {
              backgroundColor: '#1F1F1F',
            },
          }}
          onClick={() => setCategory('similar')}
        >
          <Typography className="app-text" variant="body2">
            You May Also Like
          </Typography>
        </Button>

        <Button
          variant="contained"
          className="recommendation-button"
          disableElevation
          disableFocusRipple
          disableRipple
          sx={{
            backgroundColor:
              category === 'complementary'
                ? '#1F1F1F'
                : 'rgba(31, 31, 31, 0.20)',
            '&:hover': {
              backgroundColor: '#1F1F1F',
            },
          }}
          onClick={() => setCategory('complementary')}
        >
          <Typography className="app-text" variant="body2">
            Pairs Well With
          </Typography>
        </Button>
      </Box>
    </Modal>
  );
};

export default RecommendationsModal;
