import { PropsWithChildren } from 'react';

import IconButton from '@mui/material/IconButton';

import './ARButton.scss';

export interface ARButtonProps extends PropsWithChildren {
  onClick?: () => void;
}

const ARButton: React.FC<ARButtonProps> = ({ children, onClick }) => (
  <IconButton className="ar-button" onClick={onClick}>
    {children}
  </IconButton>
);

export default ARButton;
