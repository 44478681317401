import { useEffect, useReducer } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';

import { motion, AnimatePresence } from 'framer-motion';

import ButtonBase from '@mui/material/ButtonBase';

import ARButtons from '../components/furniture/ARButtons';
import CloseButton from '../components/shared/CloseButton';
import OverlayPrompt from '../components/furniture/OverlayPrompt';
import PhotoModal from '../components/furniture/PhotoModal';
import ProductInfoModal from '../components/furniture/ProductInfoModal';
import RecommendationsModal, {
  RecommendationsCategory,
} from '../components/furniture/RecommendationsModal';
import RepositionPrompt from '../components/furniture/RepositionPrompt';
import RestartIcon from '../components/icons/RestartIcon';
import Screen from '../components/shared/Screen';
import TapPrompt from '../components/furniture/TapPrompt';

import furnitureReducer, { FurnitureActionType } from './furnitureReducer';

import { useCustomerId } from '../CustomerIdProvider';
import useUploadVideo from './useUploadVideo';
import { useAnalytics } from '../AnalyticsProvider';

import { AFRAMEElement } from '../aframe/types';

import './Furniture.scss';

const Furniture: React.FC = () => {
  const [
    {
      showOverlay,
      showTapPrompt,
      showRepositionPrompt,
      showResetButton,
      showButtons,
      showProductInfo,
      showRecommendations,
      showPhotoPreview,
      photoURI,
    },
    dispatch,
  ] = useReducer(furnitureReducer, {
    showOverlay: false,
    showTapPrompt: false,
    showRepositionPrompt: false,
    showResetButton: false,
    showButtons: false,
    showPhotoPreview: false,
    showRecommendations: false,
    showProductInfo: false,
    photoURI: '',
  });

  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();

  const customerId = useCustomerId();

  const uploadVideo = useUploadVideo();

  const analytics = useAnalytics();

  const onChangeSku = (
    selectedSku: string,
    category: RecommendationsCategory
  ) => {
    dispatch({ type: FurnitureActionType.loadNewModel });

    analytics.trackEvent(
      category === 'similar'
        ? 'similar_product_selected'
        : 'complementary_product_selected',
      {
        selected_sku: selectedSku,
      }
    );

    // Get the search params
    const searchFields: { [key: string]: string } = {};
    for (const [key, value] of searchParams.entries()) {
      searchFields[key] = value;
    }

    setSearchParams({
      ...searchFields,
      sku: selectedSku,
    });
  };

  useEffect(() => {
    const goToNoModelScreen = () =>
      navigate({
        pathname: '/no-model',
        search: searchParams.toString(),
      });

    const onOverlayChange = (evt: { detail: { shown: boolean } }) => {
      if (!evt.detail.shown) analytics.trackEvent('scale_estimated');

      dispatch({
        type: FurnitureActionType.onOverlayChange,
        shown: evt.detail.shown,
      });
    };

    const onModelPlaced = () => {
      dispatch({ type: FurnitureActionType.onModelPlaced });

      analytics.trackEvent('furniture_placed');
    };

    const onPhotoTaken = (evt: { detail: string }) => {
      dispatch({
        type: FurnitureActionType.setPhoto,
        photoUri: evt.detail,
      });

      analytics.trackEvent('photo_captured');
    };

    const scene = document.getElementById('a-scene') as AFRAMEElement;
    if (!scene) return;

    if (!searchParams.has('sku')) {
      goToNoModelScreen();
      return;
    }

    if (!scene.hasAttribute('furniture-scene')) {
      scene.emit('resume-scene');
      scene.addEventListener('furniture-overlay-changed', onOverlayChange);

      scene.addEventListener('model-error', goToNoModelScreen);
      scene.addEventListener('model-placed', onModelPlaced);

      scene.addEventListener('screenshotready', onPhotoTaken);

      scene.setAttribute('furniture-scene', `sku: ${searchParams.get('sku')};`);
    }

    return () => {
      if (!scene.hasAttribute('furniture-scene')) return;

      scene.removeEventListener('furniture-overlay-changed', onOverlayChange);

      scene.removeEventListener('model-error', goToNoModelScreen);
      scene.removeEventListener('model-placed', onModelPlaced);

      scene.removeEventListener('screenshotready', onPhotoTaken);

      scene.removeAttribute('furniture-scene');
    };
  }, [analytics, navigate, searchParams]);

  // Hide reposition after 3 seconds
  useEffect(() => {
    if (!showRepositionPrompt) return;
    setTimeout(
      () => dispatch({ type: FurnitureActionType.hideReposition }),
      3000
    );
  }, [showRepositionPrompt]);

  // Upload video
  useEffect(() => {
    const videoURL = localStorage.getItem('scanResult');
    const email = localStorage.getItem('email') || searchParams.get('email');

    (async () => {
      if (!videoURL) return;

      const done = await uploadVideo(videoURL, customerId, email);
      if (done) URL.revokeObjectURL(videoURL);

      localStorage.removeItem('scanResult');
    })();
  }, [customerId, searchParams, uploadVideo]);

  return (
    <Screen className="furniture-screen">
      <CloseButton />

      <AnimatePresence mode="wait">
        {showOverlay && <OverlayPrompt />}
        {showTapPrompt && <TapPrompt />}
        {showRepositionPrompt && <RepositionPrompt />}
        {showResetButton && (
          <ButtonBase
            className="restart-button"
            key="restart-button"
            component={motion.button}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ ease: 'easeIn', duration: 0.35 }}
            onClick={() => {
              analytics.trackEvent('reset_btn_tapped');

              const scene = document.getElementById('a-scene') as AFRAMEElement;
              scene?.emit('reset-scene');
            }}
          >
            <RestartIcon />
          </ButtonBase>
        )}
      </AnimatePresence>

      <AnimatePresence>
        {showButtons && (
          <ARButtons
            onClickCamera={() => {
              const scene = document.getElementById('a-scene') as AFRAMEElement;
              scene?.emit('screenshotrequest');
            }}
            onClickSofa={() => {
              analytics.trackEvent('more_products_tapped');
              dispatch({ type: FurnitureActionType.showRecommendations });
            }}
            onClickTag={() =>
              dispatch({ type: FurnitureActionType.showProductInfo })
            }
          />
        )}
      </AnimatePresence>

      <ProductInfoModal
        open={showProductInfo}
        onClose={() => dispatch({ type: FurnitureActionType.closeProductInfo })}
        sku={searchParams.get('sku') as string}
        returnURL={searchParams.get('return_url')}
      />

      <PhotoModal
        open={showPhotoPreview}
        photoURI={photoURI}
        onClose={() => dispatch({ type: FurnitureActionType.closePhoto })}
      />

      <RecommendationsModal
        open={showRecommendations}
        onClose={() =>
          dispatch({ type: FurnitureActionType.closeRecommendations })
        }
        sku={searchParams.get('sku') as string}
        onChangeSku={onChangeSku}
      />
    </Screen>
  );
};

export default Furniture;
