import { BrowserRouter } from 'react-router-dom';
import CssBaseline from '@mui/material/CssBaseline';
import { createTheme, ThemeProvider } from '@mui/material/styles';

import ARScene from './aframe/ARScene';

import AnalyticsProvider from './AnalyticsProvider';
import CustomerIdProvider from './CustomerIdProvider';

import AppRoutes from './AppRoutes';

const theme = createTheme({
  palette: {
    primary: {
      main: '#fff',
    },
    secondary: {
      main: '#393D48',
    },
  },
  shape: {
    borderRadius: 6,
  },
  typography: {
    fontFamily: ['SF Pro Text', 'Poppins', 'Arial', 'sans-serif'].join(','),
  },
});

function App() {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />

      <BrowserRouter>
        <CustomerIdProvider>
          <AnalyticsProvider>
            <ARScene>
              <AppRoutes />
            </ARScene>
          </AnalyticsProvider>
        </CustomerIdProvider>
      </BrowserRouter>
    </ThemeProvider>
  );
}

export default App;
