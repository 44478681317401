const getSupportedVideoType = () =>
  MediaRecorder.isTypeSupported('video/webm') ? 'video/webm' : 'video/mp4';

const STREAM_CONSTRAINTS: MediaStreamConstraints = {
  video: {
    facingMode: 'environment',
  },
};

const component = {
  el: {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    addEventListener: (name: unknown, cb: unknown) => null,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    removeEventListener: (name: unknown, cb: unknown) => null,
    sceneEl: {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      emit: (evt: string, data: unknown) => null,
    },
  },
  init() {
    let cameraStream: MediaStream | null = null;
    let mediaRecorder: MediaRecorder | null = null;
    let recordedChunks: Blob[] = [];

    const handleStopRecording = () => {
      if (!mediaRecorder || recordedChunks.length <= 0) return;

      const videoURL = URL.createObjectURL(
        new Blob(recordedChunks, {
          type: getSupportedVideoType(),
        })
      );

      this.el.sceneEl.emit('video-ready', { videoURL });

      recordedChunks = [];
    };

    const stopRecording = () => {
      if (!mediaRecorder) return;
      mediaRecorder.stop();

      this.el.sceneEl.emit('recording-stopped', '');
    };

    const startRecording = () => {
      if (!cameraStream || !mediaRecorder) return;

      mediaRecorder.start();
      this.el.sceneEl.emit('recording-started', '');
    };

    const pauseScene = async () => {
      if (!XR8.isPaused()) XR8.pause();

      try {
        if (!cameraStream) {
          cameraStream = await navigator.mediaDevices.getUserMedia(
            STREAM_CONSTRAINTS
          );

          mediaRecorder = new MediaRecorder(cameraStream, {
            mimeType: getSupportedVideoType(),
          });

          mediaRecorder.ondataavailable = (evt: BlobEvent) =>
            recordedChunks.push(evt.data);

          mediaRecorder.onstop = handleStopRecording;

          const videoElement = document.getElementById(
            'camera-feed'
          ) as HTMLVideoElement;
          videoElement.srcObject = cameraStream;
          videoElement.style.display = 'initial';
          videoElement.style.zIndex = '801';
          videoElement.play();
        }
      } catch (err) {
        cameraStream = null;
        console.error('Error accessing the camera:', err);
      }
    };

    const resumeScene = () => {
      if (cameraStream) {
        const videoElement = document.getElementById(
          'camera-feed'
        ) as HTMLVideoElement;
        videoElement.style.display = 'none';
        videoElement.style.zIndex = '-1';
        videoElement.pause();

        const tracks = cameraStream.getTracks();
        tracks.forEach((track) => track.stop());

        videoElement.srcObject = null;
        cameraStream = null;
        mediaRecorder = null;
      }

      if (XR8.isPaused()) XR8.resume();
    };

    const onAttach = () => {
      XR8.initialize().then(() => {
        this.el.addEventListener('pause-scene', pauseScene);
        this.el.addEventListener('resume-scene', resumeScene);

        this.el.addEventListener('start-recording', startRecording);
        this.el.addEventListener('stop-recording', stopRecording);

        this.el.sceneEl.emit('xr-loaded', '');
      });
    };

    const onDetach = () => {
      this.el.removeEventListener('pause-scene', pauseScene);
      this.el.removeEventListener('resume-scene', resumeScene);

      this.el.removeEventListener('start-recording', startRecording);
      this.el.removeEventListener('stop-recording', stopRecording);
    };

    const onxrloaded = () => {
      XR8.addCameraPipelineModule({
        name: 'configureScene',
        onAttach,
        onDetach,
      });
    };

    XR8 ? onxrloaded() : window.addEventListener('xrloaded', onxrloaded);
  },
  remove() {
    XR8.removeCameraPipelineModule('configureScene');
  },
};

export default component;
