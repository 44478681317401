import ButtonBase from '@mui/material/ButtonBase';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';

export interface RecommendationCardProps {
  sku: string;
  onSelect: () => void;
}

const RecommendationCard: React.FC<RecommendationCardProps> = ({
  onSelect,
  sku,
}) => (
  <ButtonBase disableRipple disableTouchRipple onClick={onSelect}>
    <Card className="recommendation-card" elevation={4}>
      <CardContent className="recommendation-card-content">
        <img
          src={`${process.env.REACT_APP_THUMBNAIL_HOST}/${sku}.jpg`}
          alt={`Product ${sku} thumbnail`}
        />
      </CardContent>
    </Card>
  </ButtonBase>
);

export default RecommendationCard;
