import {
  PropsWithChildren,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
} from 'react';
import ReactGA from 'react-ga4';
import mixpanel, { RequestOptions } from 'mixpanel-browser';
import { useLocation, useSearchParams } from 'react-router-dom';

import { useCustomerId } from './CustomerIdProvider';

import { routesTitleMap } from './AppRoutes';

interface AnalyticsContextValue {
  trackEvent: (
    eventName: string,
    params?: Record<string, unknown>,
    mpOptions?: RequestOptions,
    mpCallback?: () => void
  ) => void;
  set: (fieldsObject: Record<string, unknown>) => void;
  increment: (fieldName: string) => void;
}

export const AnalyticsContext = createContext<AnalyticsContextValue>(
  {} as AnalyticsContextValue
);

export const useAnalytics = () => useContext(AnalyticsContext);

const debug = !process.env.NODE_ENV || process.env.NODE_ENV === 'development';
const gTagId = process.env.REACT_APP_GTAG_ID || '';
const mixpanelToken = process.env.REACT_APP_MIXPANEL_TOKEN || '';

const AnalyticsProvider: React.FC<PropsWithChildren> = ({ children }) => {
  const [searchParams] = useSearchParams();
  const location = useLocation();

  const customerId = useCustomerId();

  const baseParams = useMemo(
    () => ({
      sku: searchParams.get('sku'),
      retailer_id: searchParams.get('retailer_id'),
      app_id: 'AR app',
    }),
    [searchParams]
  );

  // Methods
  const trackEvent = useCallback(
    (
      eventName: string,
      params: Record<string, unknown> = {},
      mpOptions: RequestOptions = {},
      mpCallback: () => void = () => null
    ) => {
      const eventParams = {
        ...baseParams,
        ...params,
      };

      ReactGA.event(eventName);
      mixpanel.track(eventName, eventParams, mpOptions, mpCallback);
    },
    [baseParams]
  );

  const set = (fieldsObject: Record<string, unknown>) => {
    ReactGA.event('set_fields', fieldsObject);
    mixpanel.people.set(fieldsObject);
  };

  const increment = (fieldName: string) => {
    ReactGA.event(fieldName);
    mixpanel.people.increment(fieldName);
  };

  // Init analytics & listen unload
  useEffect(() => {
    ReactGA.initialize(gTagId, {
      gaOptions: {
        userId: customerId,
      },
    });

    mixpanel.init(mixpanelToken, { debug });
    mixpanel.identify(customerId);
  }, [customerId]);

  // Track page views
  useEffect(() => {
    const searchFields: { [key: string]: string } = {};
    for (const [key, value] of searchParams.entries()) {
      searchFields[key] = value;
    }

    const params = {
      ...baseParams,
      ...searchFields,
      title: routesTitleMap[location.pathname],
      page: location.pathname,
      page_location: window.location.href,
      page_path: location.pathname,
    };

    ReactGA.send({ ...params, hitType: 'pageview' });
    mixpanel.track_pageview(params);
  }, [baseParams, location.pathname, searchParams]);

  return (
    <AnalyticsContext.Provider value={{ trackEvent, set, increment }}>
      {children}
    </AnalyticsContext.Provider>
  );
};

export default AnalyticsProvider;
