import { useCallback } from 'react';

interface SignedURLResponse {
  customerId: string;
  signedUrl: {
    contentType: string;
    url: string;
  };
}

const API_HOST = process.env.REACT_APP_API_HOST;

const useUploadVideo = () => {
  const getSignedURL = async (
    fileExt: string,
    customerId: string,
    email: string | null
  ) => {
    const response = await fetch(
      `${API_HOST}/media/scans/${customerId}/signed-url`,
      {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          fileExt,
          ...(email ? { email } : {}),
        }),
      }
    );

    if (response.ok) {
      const data = (await response.json()) as SignedURLResponse;
      return data.signedUrl.url;
    } else {
      return null;
    }
  };

  return useCallback(
    async (videoURL: string, customerId: string, email: string | null) => {
      try {
        const blob = await (await fetch(videoURL)).blob();
        const signedURL = await getSignedURL(
          blob.type.replace('video/', ''),
          customerId,
          email
        );

        if (!signedURL) return null;

        await fetch(signedURL, {
          method: 'PUT',
          body: blob,
        });

        return true;
      } catch (err) {
        return false;
      }
    },
    []
  );
};

export default useUploadVideo;
