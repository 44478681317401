import { PropsWithChildren } from 'react';

import Box from '@mui/material/Box';
import { SxProps } from '@mui/material';

import './Screen.scss';

export interface ScreenProps extends PropsWithChildren {
  className?: string;
  sx?: SxProps;
}

const Screen: React.FC<ScreenProps> = ({ children, className = '', sx }) => (
  <Box className={`screen ${className}`} sx={sx}>
    {children}
  </Box>
);

export default Screen;
