import { PropsWithChildren } from 'react';
import { motion, Variants } from 'framer-motion';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import './Modal.scss';

export interface ModalProps extends PropsWithChildren {
  title: string;
  open?: boolean;
  full?: boolean;
  left?: React.ReactNode;
  right?: React.ReactNode;
  delay?: number;
  height?: number;
  className?: string;
}

const variants: Variants = {
  initial: (height: number) => ({
    height: `${height}%`,
    top: '100%',
  }),
  open: (height: number) => ({
    height: `${height}%`,
    top: `${100 - height}%`,
  }),
  full: {
    height: '100%',
    top: '0%',
  },
  close: (height: number) => ({
    height: `${height}%`,
    top: '100%',
  }),
};

const Modal: React.FC<ModalProps> = ({
  open = false,
  full = false,
  left,
  title,
  right,
  delay = 0,
  height = 86,
  className = '',
  children,
}) => (
  <Box
    className={`modal${open ? ' open' : ' close'} ${
      full ? ' full' : ''
    }${className}`}
    component={motion.div}
    variants={variants}
    initial="initial"
    animate={full ? 'full' : open ? 'open' : 'close'}
    transition={{ delay }}
    custom={height}
  >
    <Box className="modal-title-bar">
      {left}
      <Typography variant="h6" className="app-text modal-title">
        {title}
      </Typography>
      {right}
    </Box>

    <Box className="modal-content">{children}</Box>
  </Box>
);

export default Modal;
