import Cookies from 'js-cookie';

import { useEffect, useRef, useState } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { useNavigate, useSearchParams } from 'react-router-dom';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';

import Arrow from '../components/scan/Arrow';
import CloseButton from '../components/shared/CloseButton';
import ScanHint from '../components/furniture/ScanHint';
import Screen from '../components/shared/Screen';

import { useAnalytics } from '../AnalyticsProvider';

import { AFRAMEElement } from '../aframe/types';

import './Scan.scss';

const RECORDING_TIME = 6000;

const Scan: React.FC = () => {
  const [recording, setRecording] = useState(false);
  const [done, setDone] = useState(false);

  const counterPlayingRef = useRef(false);
  const counterElem = useRef<HTMLHeadElement>(null);

  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  const analytics = useAnalytics();

  const startRecording = () => {
    const scene = document.getElementById('a-scene') as AFRAMEElement;
    if (!scene) return;

    scene.emit('start-recording');

    // Start counting
    counterPlayingRef.current = true;

    const time = Date.now();
    const counter = () => {
      const duration = Date.now() - time;

      if (counterElem.current) {
        counterElem.current.innerHTML = `${Math.ceil(
          (RECORDING_TIME - duration) / 1000
        )}`;
      }

      if (RECORDING_TIME <= duration) {
        counterPlayingRef.current = false;
        scene.emit('stop-recording');

        return;
      }

      if (counterPlayingRef.current) {
        requestAnimationFrame(counter);
      }
    };

    counter();
  };

  useEffect(() => {
    const scene = document.getElementById('a-scene') as AFRAMEElement;
    if (!scene) return;

    const onVideoReady = (evt: { detail: { videoURL: string } }) => {
      localStorage.setItem('scanResult', evt.detail.videoURL);

      analytics.trackEvent('room_scanned');
      analytics.increment('room_scanned');

      setRecording(false);
      setDone(true);
    };

    scene.emit('pause-scene');
    scene.addEventListener('video-ready', onVideoReady);

    return () => {
      scene.removeEventListener('video-ready', onVideoReady);
    };
  }, [analytics]);

  useEffect(() => {
    if (Cookies.get('scanned') === 'true') {
      navigate({
        pathname: '/furniture-viewer',
        search: searchParams.toString(),
      });
    }
  }, [navigate, searchParams]);

  return (
    <Screen className="scan-screen">
      <CloseButton />

      <AnimatePresence mode="wait">
        {!recording && !done && (
          <Box
            className="scan-instructions"
            key="scan-instructions"
            component={motion.div}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ ease: 'easeIn', duration: 0.25 }}
          >
            <Box className="scan-content">
              <Box className="scan-action">
                <Box className="scan-hint">
                  <ScanHint />
                </Box>
                <Box className="scan-arrow">
                  <Arrow />
                  <Typography
                    className="app-text"
                    variant="body2"
                    sx={{ textAlign: 'center' }}
                  >
                    5 secs
                  </Typography>
                </Box>
              </Box>
            </Box>

            <Box className="scan-footer">
              <Typography
                className="app-text"
                variant="subtitle1"
                sx={{
                  fontWeight: 600,
                  lineHeight: '22px',
                  letterSpacing: '-0.408px',
                }}
              >
                SCAN YOUR ROOM
              </Typography>
              <Typography
                className="app-text"
                variant="body2"
                sx={{
                  mt: '8px',
                  mb: '16px',
                  lineHeight: 'normal',
                  letterSpacing: '-0.408px',
                }}
              >
                Pan your phone from left to right for 5 seconds to help us
                understand your space.
                <br />
                Hit &quot;Start Scan&quot; to begin.
              </Typography>

              <Button
                className="furniture-button"
                variant="contained"
                disableElevation
                onClick={() => {
                  analytics.trackEvent('start_scan_tapped');

                  setRecording(true);
                  startRecording();
                }}
              >
                <Typography
                  className="app-text"
                  variant="subtitle2"
                  sx={{
                    fontFamily: 'Poppins',
                    fontWeight: 600,
                    lineHeight: '22px',
                    letterSpacing: '-0.408px',
                  }}
                >
                  Start Scan
                </Typography>
              </Button>
            </Box>
          </Box>
        )}

        {recording && !done && (
          <Box
            className="scan-recording"
            key="scan-recording"
            component={motion.div}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ ease: 'easeIn', duration: 0.25 }}
          >
            <Box className="scan-countdown">
              <Typography
                className="app-text"
                variant="h6"
                ref={counterElem}
                sx={{ fontWeight: 600 }}
              />
            </Box>
            <Typography
              className="app-text"
              variant="subtitle1"
              sx={{
                mt: '16px',
                mb: '24px',
                fontFamily: 'Poppins',
                fontSize: '14px',
                fontWeight: 600,
                lineHeight: '22px',
                letterSpacing: '-0.408px',
              }}
            >
              Scanning...
            </Typography>
          </Box>
        )}

        {!recording && done && (
          <Box
            className="scan-done"
            key="scan-done"
            component={motion.div}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ ease: 'easeIn', duration: 0.25 }}
          >
            <Box className="scan-done-dialog">
              <Typography
                className="app-text"
                variant="subtitle1"
                sx={{
                  fontWeight: 600,
                  lineHeight: '22px',
                  letterSpacing: '-0.408px',
                }}
              >
                DONE CAPTURING YOUR SPACE?
              </Typography>
              <Typography
                className="app-text"
                variant="body2"
                sx={{
                  mt: '24px',
                  mb: '24px',
                  textAlign: 'center',
                  lineHeight: 'normal',
                  letterSpacing: '-0.408px',
                }}
              >
                If you were able to scan your space, hit &quot;Continue&quot;.
                If not, hit &quot;Scan Again&quot; to retry.
              </Typography>
              <Box className="scan-done-options">
                <Button
                  className="furniture-button"
                  variant="contained"
                  disableElevation
                  onClick={() => {
                    analytics.trackEvent('scan_again_tapped');

                    setDone(false);
                    setRecording(true);
                    startRecording();
                  }}
                >
                  <Typography
                    variant="body2"
                    sx={{
                      fontFamily: 'Poppins',
                      fontWeight: 500,
                      letterSpacing: '-0.408px',
                    }}
                  >
                    Scan Again
                  </Typography>
                </Button>
                <Button
                  className="furniture-button"
                  variant="contained"
                  disableElevation
                  onClick={() => {
                    analytics.trackEvent('scan_continue_tapped');

                    // Set Cookie for one day
                    Cookies.set('scanned', 'true', {
                      domain: window.location.hostname,
                      path: '/',
                      expires: 1,
                    });

                    navigate({
                      pathname: '/furniture-viewer',
                      search: searchParams.toString(),
                    });
                  }}
                >
                  <Typography
                    className="app-text"
                    variant="body2"
                    sx={{
                      fontFamily: 'Poppins',
                      fontWeight: 600,
                      letterSpacing: '-0.408px',
                    }}
                  >
                    Continue
                  </Typography>
                </Button>
              </Box>
            </Box>
          </Box>
        )}
      </AnimatePresence>
    </Screen>
  );
};

export default Scan;
